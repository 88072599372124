import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './pages/login'; 
import Introducao from './pages/introducao';
import Laudos from './pages/laudos';
import AdminPage from './pages/adminPage';
import UsuarioPerfil from './pages/usuarioPerfil';
import RecuperarSenha from './pages/recuperarSenha';
import ProtectedRoute from './componets/protectedRoute';

const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = () => {
            const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
            const admin = JSON.parse(localStorage.getItem('admin')) || JSON.parse(sessionStorage.getItem('admin'));
            return { user, admin };
        };

        const { user, admin } = checkAuthentication();

        if (admin || user) {
           
        }
    }, [navigate]);

    return (
        <Routes>
            <Route path="/" element={<Introducao />} />
            <Route path="/login" element={<Login />} />
            <Route path='/recuperarSenha' element={<RecuperarSenha />} />
            <Route path="/laudos" element={
                <ProtectedRoute allowedRoles={['user', 'admin']}>
                    <Laudos />
                </ProtectedRoute>
            } />
            <Route path="/admin" element={
                <ProtectedRoute allowedRoles={['admin']}>
                    <AdminPage />
                </ProtectedRoute>
            } />
            <Route path="/perfil" element={
                <ProtectedRoute allowedRoles={['user', 'admin']}>
                    <UsuarioPerfil />
                </ProtectedRoute>
            } />
        </Routes>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);
