import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/introducao.scss';
import logo from '../assets/Logo.png';

function Introducao() {
  const navigate = useNavigate();

  // Verificação de autenticação ao carregar a página
  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      const getUserRole = async () => {
        try {
          const response = await axios.get('https://bplaudos.com.br/api/user/profile', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const role = response.data.role;
          if (role === 'admin') {
            navigate('/admin');
          } else {
            navigate('/laudos');
          }
        } catch (error) {
          console.error('Erro ao obter perfil do usuário:', error);
        }
      };

      getUserRole();
    }
  }, [navigate]);

  const handleGenerateReports = () => {
    const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
    const admin = JSON.parse(localStorage.getItem('admin')) || JSON.parse(sessionStorage.getItem('admin'));

    if (admin) {
      navigate('/admin');
    } else if (user) {
      navigate('/laudos');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className='introducao-body'>
      <header className='introducao-header'>
        <img src={logo} alt="Logo" />
        <p>Seja bem-vindo(a)</p>
      </header>
      <main className='introducao-main'>
        <h1>A melhor forma de gerar seu laudo</h1>
        <p>Incorpore o software de automação ao fluxo do seu trabalho, acelerando e tornando eficiente a geração dos seus laudos.</p>
        <button onClick={handleGenerateReports}>Gerar Laudos</button>
      </main>
    </div>
  );
}

export default Introducao;
