import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/adminPage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/Logo.png';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function AdminPage() {
    const [usuarios, setUsuarios] = useState([]);
    const [usuario, setUsuario] = useState('');
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaVisivel, setSenhaVisivel] = useState(false);
    const [dataExpiracao, setDataExpiracao] = useState('');
    const [role, setRole] = useState('user');
    const [mensagem, setMensagem] = useState('');
    const [searchText, setSearchText] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editingUserId, setEditingUserId] = useState(null);
    const [menuAberto, setMenuAberto] = useState(false);
    const navigate = useNavigate();

    const alternarVisibilidadeDaSenha = () => {
        setSenhaVisivel(!senhaVisivel);
    };

    const alternarMenu = () => {
        setMenuAberto(!menuAberto);
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        localStorage.removeItem('admin');
        sessionStorage.removeItem('admin');
        navigate('/login');
    };


    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await axios.get('https://bplaudos.com.br/api/users/list');
                setUsuarios(response.data);
            } catch (error) {
                console.error('Erro ao buscar usuários:', error);
            }
        };
        fetchUsuarios();
    }, []);

    const resetForm = () => {
        setUsuario('');
        setNome('');
        setSobrenome('');
        setTelefone('');
        setEmail('');
        setSenha('');
        setDataExpiracao('');
        setRole('user');
        setMensagem('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                usuario,
                nome,
                sobrenome,
                telefone,
                email,
                dataExpiracao,
                role,
                senha,
            };

            console.log("Enviando payload:", payload);

            const admin = JSON.parse(localStorage.getItem('admin')) || JSON.parse(sessionStorage.getItem('admin'));

            if (!admin || !admin.token) {
                console.error('Token de autenticação não encontrado');
                setMensagem('Erro ao criar/atualizar usuário: Autenticação necessária');
                return;
            }

            const headers = {
                Authorization: `Bearer ${admin.token}`,
            };

            if (editMode) {
                const response = await axios.put(
                    `https://bplaudos.com.br/api/users/update/${editingUserId}`,
                    payload,
                    { headers }
                );
                console.log('Resposta do servidor:', response.data);
                setMensagem('Usuário atualizado com sucesso');

                if (senha) {
                    await axios.put(
                        `https://bplaudos.com.br/api/users/update-admin-senha/${editingUserId}`,
                        { novaSenha: senha },
                        { headers }
                    );
                    setMensagem('Senha atualizada com sucesso');
                }

                if (usuario) {
                    await axios.put(
                        `https://bplaudos.com.br/api/users/update-usuario/${editingUserId}`,
                        { novoUsuario: usuario },
                        { headers }
                    );
                    setMensagem('Usuário atualizado com sucesso');
                }
            } else {
                const response = await axios.post(
                    'https://bplaudos.com.br/api/users/add',
                    payload,
                    { headers }
                );
                console.log('Resposta do servidor:', response.data);
                setMensagem('Usuário criado com sucesso');
            }

            setShowForm(false);
            resetForm();
            const updatedResponse = await axios.get('https://bplaudos.com.br/api/users/list', { headers });
            setUsuarios(updatedResponse.data);
        } catch (error) {
            console.error('Erro ao criar/atualizar usuário:', error.response ? error.response.data : error.message);
            setMensagem('Erro ao criar/atualizar usuário');
        }
    };


    const handleFormClose = () => {
        setShowForm(false);
        setEditMode(false);
        resetForm();
    };

    const handleEdit = (usuario) => {
        setUsuario(usuario.usuario);
        setNome(usuario.nome);
        setSobrenome(usuario.sobrenome);
        setTelefone(usuario.telefone);
        setEmail(usuario.email);
        setDataExpiracao(new Date(usuario.dataExpiracao).toISOString().split('T')[0]);
        setRole(usuario.role);
        setEditingUserId(usuario.id);
        setShowForm(true);
        setEditMode(true);
        setSenha('');
    };


    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://bplaudos.com.br/api/users/delete/${id}`);
            setMensagem('Usuário removido com sucesso');
            const updatedResponse = await axios.get('https://bplaudos.com.br/api/users/list');
            setUsuarios(updatedResponse.data);
            setShowForm(false);
            setEditMode(false);
            resetForm();
        } catch (error) {
            setMensagem('Erro ao remover usuário');
        }
    };
    const handleExtend = async (userId) => {
        try {
            const admin = JSON.parse(localStorage.getItem('admin')) || JSON.parse(sessionStorage.getItem('admin'));

            if (!admin || !admin.token) {
                console.error('Token de autenticação não encontrado');
                setMensagem('Erro: Autenticação necessária.');
                return;
            }

            const headers = {
                Authorization: `Bearer ${admin.token}`,
            };

            const response = await axios.put(`https://bplaudos.com.br/api/users/extend/${userId}`, {}, { headers });
            console.log('Resposta do servidor:', response.data);

            setMensagem('Acesso estendido com sucesso');
            setUsuarios(usuarios.map(usuario =>
                usuario.id === userId ? { ...usuario, dataExpiracao: response.data.dataExpiracao } : usuario
            ));
        } catch (error) {
            console.error('Erro ao estender acesso:', error.response ? error.response.data : error.message);
            setMensagem('Erro ao estender acesso.');
        }
    };



    const filteredUsuarios = usuarios.filter(usuario =>
        usuario.nome.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className='body-adm'>
            <div className="laudos-link-menu">
                <div className="laudos-link-perfil" onClick={alternarMenu}>
                    <span>
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                    {menuAberto && (
                        <div className="menu-suspenso">
                            <ul className={menuAberto ? 'menu-ativo' : ''}>
                                <li onClick={() => navigate('/laudos')}>Laudos </li>
                                <li onClick={handleLogout}>Sair</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            {showForm && (
                <div className='adm-conteiner-usuario-form'>
                    <div className='formulario-add'>
                        <h1>{editMode ? 'Editar usuário' : 'Novo usuário'}</h1>
                        <button className='fecharMenu' onClick={handleFormClose}>X</button>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label htmlFor='usuario'>Usuário:</label>
                                <input type='text' id='usuario' value={usuario} onChange={(e) => setUsuario(e.target.value)} required />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='nome'>Nome:</label>
                                <input type='text' id='nome' value={nome} onChange={(e) => setNome(e.target.value)} required />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='sobrenome'>Sobrenome:</label>
                                <input type='text' id='sobrenome' value={sobrenome} onChange={(e) => setSobrenome(e.target.value)} required />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='telefone'>Telefone:</label>
                                <input type='text' id='telefone' value={telefone} onChange={(e) => setTelefone(e.target.value)} required />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='email'>Email:</label>
                                <input type='email' id='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>

                            <div className='form-group-s'>
                                <label htmlFor='senha'>Senha:</label>
                                <div className='senha'>
                                    <input
                                        type={senhaVisivel ? 'text' : 'password'}
                                        id="senha"
                                        placeholder="Digite uma nova senha"
                                        value={senha}
                                        onChange={(e) => setSenha(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        onClick={alternarVisibilidadeDaSenha}
                                        aria-label={senhaVisivel ? 'Esconder senha' : 'Mostrar senha'}
                                    >
                                        <FontAwesomeIcon icon={senhaVisivel ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Data de Expiração:</label>
                                <input type='date' value={dataExpiracao} onChange={(e) => setDataExpiracao(e.target.value)} required />
                            </div>
                            <div className='form-group'>
                                <label>Cargo:</label>
                                <select value={role} onChange={(e) => setRole(e.target.value)} required>
                                    <option value='user'>Usuário</option>
                                    <option value='admin'>Administrador</option>
                                </select>
                            </div>
                            <div className='form-btn-add'>
                                <button type='submit'>{editMode ? 'Atualizar Usuário' : 'Adicionar Usuário'}</button>
                                {editMode && (
                                    <button type='button' className='delete' onClick={() => handleDelete(editingUserId)}><FontAwesomeIcon icon={faTrash} /></button>
                                )}
                            </div>
                            <div className='erro-texto'>
                                {mensagem && <p>{mensagem}</p>}
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <main className='adm-conteiner'>
                <header className='adm-conteiner-pesquisar'>
                    <input
                        type='text'
                        placeholder='Pesquisar'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </header>
                <h1>Usuários</h1>
                <div className='adm-conteiner-usuarios'>
                    {filteredUsuarios.map((usuario) => (
                        <div className='adm-conteiner-usuario' key={usuario.id}>
                            <div className='adm-conteiner-usuario-info'>
                                <label>Usuario: <p>{usuario.usuario}</p></label>
                                <label>Nome: <p>{usuario.nome}</p></label>
                                <label>Sobrenome: <p>{usuario.sobrenome}</p></label>
                                <label>Telefone: <p>{usuario.telefone}</p></label>
                                <label>Email: <p>{usuario.email}</p></label>
                                <label>Data de venc.: <p>{new Date(usuario.dataExpiracao).toLocaleDateString()}</p></label>
                                <div className='adm-conteiner-usuario-btn'>
                                    <button className={new Date() <= new Date(usuario.dataExpiracao) ? 'permitir' : 'nao-permitir'}
                                        onClick={() => handleExtend(usuario.id)}>
                                        {new Date() <= new Date(usuario.dataExpiracao) ? 'Permitido' : 'Não Permitido'}
                                    </button>
                                    <button className='edit' onClick={() => handleEdit(usuario)}><FontAwesomeIcon icon={faPen} /></button>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='adm-conteiner-usuario-add' onClick={() => { setShowForm(true); setEditMode(false); resetForm(); }}>
                        <button>+</button>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default AdminPage;
