import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../styles/recuperarSenha.scss';

function RecuperarSenha() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [codigo, setCodigo] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
    const [senhaVisivel, setSenhaVisivel] = useState(false);
    const [confirmarSenhaVisivel, setConfirmarSenhaVisivel] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const navigate = useNavigate();

    const alternarVisibilidadeDaSenha = () => {
        setSenhaVisivel(!senhaVisivel);
    };

    const alternarVisibilidadeDaConfirmarSenha = () => {
        setConfirmarSenhaVisivel(!confirmarSenhaVisivel);
    };

    const handleEsqueciSenha = async (e) => {
        e.preventDefault();
        setMensagem('Um código de verificação está sendo enviado para o seu email.');
        
        try {
            await axios.post('https://bplaudos.com.br/api/users/esqueci-senha', { email });
            setMensagem('Um código de verificação foi enviado para o seu email.');
            setStep(2); // Mover para a próxima fase somente se o código for enviado com sucesso
        } catch (error) {
            setMensagem('Erro ao enviar código de verificação.');
        }
    };
    

    const handleAutenticacao = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://bplaudos.com.br/api/users/verificar-codigo', { email, codigo });
            setMensagem('Código verificado com sucesso.');
            setStep(3);
        } catch (error) {
            setMensagem('Código inválido ou expirado.');
        }
    };

    const handleRedefinirSenha = async (e) => {
        e.preventDefault();
        if (novaSenha !== confirmarNovaSenha) {
            setMensagem('As senhas não coincidem.');
            return;
        }
        try {
            await axios.post('https://bplaudos.com.br/api/users/redefinir-senha', { email, novaSenha });
            setMensagem('Senha redefinida com sucesso.');
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            setMensagem('Erro ao redefinir a senha.');
        }
    };

    return (
        <div className="recuperar-senha-body">
            <div className="recuperar-senha-conteiner">
                {step === 1 && (
                    <>
                        <h1>Esqueci a Senha</h1>
                        <form onSubmit={handleEsqueciSenha}>
                            <div className='recuperar-senha-conteudo'>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <div className='recuperar-senha-codigo'>
                                    {mensagem && <p>{mensagem}</p>}
                                </div>
                            </div>
                            <div className='recuperar-senha-btn'>
                                <button type="submit">Enviar Código</button>
                            </div>
                        </form>
                    </>
                )}
                {step === 2 && (
                    <>
                        <h1>Autenticação de Código</h1>
                        <form onSubmit={handleAutenticacao}>
                            <div className='recuperar-senha-conteudo'>
                                <label htmlFor="codigo">Código de Verificação:</label>
                                <input
                                    type="text"
                                    id="codigo"
                                    value={codigo}
                                    onChange={(e) => setCodigo(e.target.value)}
                                    required
                                />
                                <div className='recuperar-senha-codigo'>
                                    {mensagem && <p>{mensagem}</p>}
                                </div>
                            </div>
                            <div className='recuperar-senha-btn'>
                                <button type="submit">Verificar Código</button>
                            </div>
                        </form>
                    </>
                )}
                {step === 3 && (
                    <>
                        <h1>Redefinir Senha</h1>
                        <form onSubmit={handleRedefinirSenha}>
                            <div className='recuperar-senha-conteudo'>
                                <label htmlFor="novaSenha">Nova Senha:</label>
                                <div className='recuperar-senha-senha-input'>
                                    <input
                                        type={senhaVisivel ? 'text' : 'password'}
                                        id="novaSenha"
                                        value={novaSenha}
                                        onChange={(e) => setNovaSenha(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={alternarVisibilidadeDaSenha}
                                        aria-label={senhaVisivel ? 'Esconder senha' : 'Mostrar senha'}
                                    >
                                        <FontAwesomeIcon icon={senhaVisivel ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <label htmlFor="confirmarNovaSenha">Confirmar Nova Senha:</label>
                                <div className='recuperar-senha-senha-input'>
                                    <input
                                        type={confirmarSenhaVisivel ? 'text' : 'password'}
                                        id="confirmarNovaSenha"
                                        value={confirmarNovaSenha}
                                        onChange={(e) => setConfirmarNovaSenha(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={alternarVisibilidadeDaConfirmarSenha}
                                        aria-label={confirmarSenhaVisivel ? 'Esconder senha' : 'Mostrar senha'}
                                    >
                                        <FontAwesomeIcon icon={confirmarSenhaVisivel ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <div className='recuperar-senha-codigo'>
                                    {mensagem && <p>{mensagem}</p>}
                                </div>
                            </div>
                            <div className='recuperar-senha-btn'>
                                <button type="submit">Redefinir Senha</button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

export default RecuperarSenha;
